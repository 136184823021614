import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import { animated, Spring, config } from 'react-spring/renderprops.cjs'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

import Layout from '../components/layout';
import SEO from '../components/seo'

import { Button, Container } from '../components/Elements'
import { Hero } from '../components/Sections'

const Image = styled(Img)`
  box-shadow: 3px 10px 52px -3px rgba(0,0,0,0.33);
`
const TechButton = styled.a`
  margin: 0 1rem;
  white-space: nowrap;
  text-decoration: none;
  position: relative;
  font-weight: 600;
  color: #323232;
  &:first-child {
    margin-left: 0rem;
  }
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    ${'' /* background-color: rgba(156,233,192,0.5); */}
    background-color: rebeccapurple;

    width: 100%;
    height: 8px;
    z-index: -1;
    opacity: 0.3;
  }
`
const LGrid = styled(Grid)`
  margin-left: 0;
  padding-left: 0;
`
const CheckItButton = styled.a`
  padding: 1rem 2rem;
  text-decoration: none;
  border: 2px solid rebeccapurple;
  border-radius: 5px;
  &:hover {
    border: 2px solid white;
    background-color: rebeccapurple;
    color: white;
  }
`

const PaddedContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const PaddedRow = styled(Row)`
  padding-top: ${props => props.pagination ? '0rem' : '5rem'};
  padding-bottom: ${props => props.pagination ? '5rem' : '2.5rem'};
  margin-left: 0;
  margin-right: 0;
`

const portfolioTemplate = ({ data }) => {
  const { descriptionNode, heading, imageScreenshot, link, order, subheading, screenshot, techImage } = data.datoCmsPortfolio
  return (
    <Layout>
      <SEO 
        title={ `${heading} - Project Page`} 
        description={ descriptionNode.childMarkdownRemark.excerpt }
      />
      <Hero image={ screenshot } text={{ heading, subheading }} />
      <Container type="text">
        <LGrid>
          <Row>
            { techImage.map(( item, index ) => {
              return (
                <Col key={ index }>
                  <TechButton>
                    <strong>{ item.techType }</strong>
                  </TechButton>
                </Col>
              )
            })}
          </Row>
        </LGrid>
      </Container>
      <PaddedContainer type="text">
        <Spring native config={config.slow} delay={500} from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {props => (
            <animated.div style={props}>
              <div dangerouslySetInnerHTML={{ __html: descriptionNode.childMarkdownRemark.html }}></div>
            </animated.div>
          )}
        </Spring>
      </PaddedContainer>
      <Container type="text">
        <Spring native config={config.slow} delay={900} from={{ opacity: 0, transform: 'translate3d(0, 80px, 0)' }}
          to={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}>
          {props => (
            <animated.div style={props}>
              <a href={ link } target="_blank" rel="noreferrer noopener prefetch prerender">
                <Image fluid={ imageScreenshot.fluid } />
              </a>
            </animated.div>
          )}
        </Spring>
      </Container>
      <PaddedRow center='xs'>
        <Col>
          <CheckItButton href={ link } target="_blank" rel="noreferrer noopener prefetch prerender">
            <strong>Check it out!</strong>
          </CheckItButton>
        </Col>
      </PaddedRow>
      <PaddedRow center='xs' pagination>
        { data.allDatoCmsPortfolio.edges.map(( item, index ) => {
          if (order === item.node.order && item.previous !== null) {
            return (
              <Col key={ index }>
                <Button spaced to={ item.previous.uid }>
                  <strong>Previous Project</strong>
                </Button>
              </Col>
            )
          } else {
            return null
          }
        })}
        { data.allDatoCmsPortfolio.edges.map(( item, index ) => {
          if (order === item.node.order && item.next !== null) {
            return (
              <Col key={ index }>
                <Button spaced to={ item.next.uid }>
                  <strong>Next Project</strong>
                </Button>
              </Col>
            )
          } else {
            return null
          }
        })}
      </PaddedRow>
    </Layout>
  );
};

export default portfolioTemplate;

export const pageQuery = graphql`
  query PortfolioBySlug($uid: String!) {
    allDatoCmsPortfolio(sort: {fields: [order], order: ASC} ) {
      edges {
        next {
          order
          uid
        }
        node {
          order
          uid
        }
        previous {
          order
          uid
        }
      }
    }
    datoCmsPortfolio(uid: { eq: $uid }) {
      heading
      subheading
      screenshot {
        fluid(maxWidth: 800) {
          ...GatsbyDatoCmsFluid
        }
      }
      techImage {
        techType
      }
      description
      descriptionNode {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      imageScreenshot {
        fluid(maxWidth: 800) {
          ...GatsbyDatoCmsFluid
        }
      }
      link
      uid
      order
    }
    datoCmsMainpage {
      videoBg {
        url
      }
      videoBgPlaceholder {
        url
      }
    }
  }
`;
